import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "@/assets/css/bootstrap.min.css"
// import "@/assets/css/material-design-iconic-font.min.css"
// import "@/assets/css/style.min.css";

import "@/assets/css/colors/default.css"

createApp(App).use(router).mount('#app')
