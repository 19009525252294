<script>
</script>


<template>
  <section class="home" id="home">
    <br />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="home-wrapper ">
            <h1>head<span class="text-colored">down.dk</span>
            </h1>
            <br />
          </div>
          <!-- home wrapper -->
          <br />
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
</template>
